import { motion } from 'framer-motion';
import React, { ReactNode } from 'react'
import useGetScrollDirection from '../hooks/useGetScrollDirection';


const FadeIn = ({children,delay,y=40,duration=0.3}:{children:ReactNode,delay?:number,y?:number,duration?:number}) => {
  const direction = useGetScrollDirection()

  const animationValue = direction === "up"?{y:0,opacity:1}:{y:0,opacity:1} 
  const initialAnimationValue = direction === "down"?{y:-1*y,opacity:0}:{y:y,opacity:0} 
  return (

    <motion.div whileInView={animationValue} 
    initial = {initialAnimationValue}
    transition={{delay:delay,duration:duration}}>{children}</motion.div>
  )
}

export default FadeIn