import Paragraph from "@/lib/Layout/Wrappers/Paragraph";
import Section from "@/lib/Layout/Wrappers/Section";
import styles from "styles/styles.json";
import { TiStarFullOutline, TiStarHalf } from "react-icons/ti";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaAppStoreIos } from "react-icons/fa";
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import FadeIn from "@/lib/AnimationComponents/FadeIn";
import { popChildrenIn } from "@/lib/AnimationComponents/variants";
import Image from "next/image";
import Link from "next/link";

let arr = [1, 2, 3, 4, 5];

const insightVariants = popChildrenIn();

type Window = {
  width: number;
  height: number;
};

interface AppInsights {
  id?: number;
  ratings?: number;
  platformIcon?: any;
  platformTitle?: string;
  platformImage: string;
  windowSize?: Window;
  link: string;
  alt: string;
}

function Insights() {
  const [windowSize, setWindowSize] = useState<Window>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window?.innerWidth,
        height: window?.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let appInsights: AppInsights[] = [
    {
      id: 1,
      ratings: 4.3,
      platformIcon: (
        <IoLogoGooglePlaystore
          size={windowSize.width! < 760 ? 15 : 25}
          className="text-black-400 max-w-[25px]"
        />
      ),
      platformTitle: "Play Store",
      platformImage:
        "https://assets.bonatra.com/package_detail/Googleplay.webp",
      link: "https://play.google.com/store/apps/details?id=com.yoihealth",
      alt: "Google play logo",
    },
    {
      id: 2,
      ratings: 4.4,
      platformIcon: (
        <FaAppStoreIos
          size={windowSize.width! < 760 ? 15 : 25}
          className="text-black-400 max-w-[25px]"
        />
      ),
      platformTitle: "App Store",
      platformImage:
        "https://assets.bonatra.com/package_detail/vecteezy_get-it-on-play-store-button-and-apple-app-store-icon_-2.webp",
      link: "https://apps.apple.com/in/app/bonatra/id1600079061",
      alt: "app store",
    },
  ];

  const mockups = [
    {
      image:
        "https://assets.bonatra.com/package_detail/Artboard%20%E2%80%93%204_google-pixel4-clearlywhite-portrait.webp",
      alt: "bonatra- calories -mobile interface",
    },
    {
      image:
        "https://assets.bonatra.com/package_detail/Artboard%20%E2%80%93%2012_google-pixel4-clearlywhite-portrait.webp",
      alt: "bonatra- calorie status -mobile interface",
    },
    {
      image:
        "https://assets.bonatra.com/package_detail/Artboard%20%E2%80%93%2030_google-pixel4-clearlywhite-portrait.webp",
      alt: "bonatra - sensor -mobile interface",
    },
    {
      image:
        "https://assets.bonatra.com/package_detail/Untitled%20design%20(75)_google-pixel4-clearlywhite-portrait.webp",
      alt: "Bonatra -steps count -mobile interface",
    },
  ];

  return (
    <Section>
      <article className="flex flex-col gap-8 ">
        <FadeIn duration={0.4}>
          <p className="pb-4  text-center text-6xl font-semibold">
            Service That Inspires Trust
          </p>
        </FadeIn>
        <FadeIn duration={0.6}>
          <Paragraph className="text-center text-lg">
            Bonatra has garnered over 2300 satisfied customers who have
            expressed their high level of contentment with the company&#39;s
            services and products by leaving glowing reviews on the app store
            and play store.
          </Paragraph>
        </FadeIn>
        <div className="flex flex-row gap-8 justify-center md:gap-16">
          {appInsights.map(
            ({
              id,
              ratings,
              platformIcon,
              platformImage,
              platformTitle,
              link,
              alt,
            }) => {
              return (
                <AppInsights
                  key={id}
                  id={id}
                  ratings={ratings}
                  platformIcon={platformIcon}
                  platformImage={platformImage}
                  platformTitle={platformTitle}
                  link={link}
                  alt={alt}
                />
              );
            }
          )}
        </div>
      </article>
      <motion.div
        className={`flex flex-row justify-center flex-wrap gap-16 md:gap-8 pt-10  `}
        variants={insightVariants.container}
        initial="hidden"
        whileInView={"show"}
      >
        {mockups?.map(
          ({ image, alt }: { image: string; alt: string }, index) => {
            return (
              <motion.div key={index} variants={insightVariants.child}>
                <InsightCard image={image} alt={alt} windowSize={windowSize} />
              </motion.div>
            );
          }
        )}
      </motion.div>
    </Section>
  );
}

export default Insights;

export const InsightCard = ({
  image,
  windowSize,
  alt,
}: {
  image: string;
  alt: string;
  windowSize: { width?: number | undefined; height?: number | undefined };
}) => {
  const height = windowSize.width! < 760 ? 100 : 200;
  const width = windowSize.height! < 760 ? 100 : 200;
  return (
    <div className="relative rounded-[20px] flex justify-center h-48 md:h-96 md:w-48 w-32 ">
      <Image
        src={image}
        alt={alt}
        style={{ objectFit: "contain" }}
        height={height}
        width={width}
      />
    </div>
  );
};

export const AppInsights = ({
  ratings,
  platformIcon,
  platformTitle,
  windowSize,
  platformImage,
  link,
  alt,
}: AppInsights) => {
  return (
    <Link href={link} target="_blank">
      <div className={`${styles.flexCenter} flex-col cursor-pointer`}>
        <div className={`${styles.flexCenter} flex-row`}>
          <p className="text-2xl md:text-6xl">{ratings}</p>
          <div className="pt-2 pl-3">
            <div className="flex flex-row justify-center items-center gap-2">
              {platformIcon}
              <p className="text-xl font-semibold">{platformTitle}</p>
            </div>
            <div className="flex flex-row items-center ">
              {arr?.map((item, id) => {
                return (
                  <div key={id}>
                    {id < 4 ? (
                      <TiStarFullOutline
                        size={windowSize?.width! < 760 ? 8 : 25}
                        className="text-yellow-400 max-w-[16px] md:max-w-[24px] "
                      />
                    ) : (
                      <TiStarHalf
                        size={windowSize?.width! < 760 ? 8 : 25}
                        className="text-yellow-400 max-w-[16px] md:max-w-[24px] "
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="w-32 h-4 relative m-auto my-4">
        <Image
          src={platformImage}
          alt={alt}
          style={{ objectFit: "cover" }}
          width={100}
          height={100}
        />
      </div>
    </Link>
  );
};
