import {useCallback, useEffect, useState} from 'react';
const useGetScrollDirection = () =>{
    let oldScrollY = 0;

    const [direction, setDirection] = useState('up');

    const controlDirection = useCallback( () => {
        if(window.scrollY > oldScrollY) {
            setDirection('down');
        } else {
            setDirection('up');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        oldScrollY = window.scrollY;
    },[])
    
    useEffect(() => {
        window.addEventListener('scroll', controlDirection);
        return () => {
            window.removeEventListener('scroll', controlDirection);
        };
    },[controlDirection]);
    return direction
}

export default useGetScrollDirection as ()=>string